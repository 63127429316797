(function () {

	"use strict";

    var req = new XMLHttpRequest();
    var onStateChange = function () {

        if (this.readyState === 4 && this.status === 200) {

            var cart, resp = this.responseText.trim();

            try {

            	resp = JSON.parse(resp);
            	cart = document.getElementById("cart_top").firstElementChild;

            	cart.setAttribute("data-cart", parseFloat(resp.cart_length));
            } catch (err) {

            	console.error(err);
            }
        }
    };

    req.addEventListener("readystatechange", onStateChange);

    req.open("GET", path_relative_root + "my_account_data.php", true);
    req.send(null);
}());
