$(function () {

    if($('body').hasClass('category')){
        // On regarde si on est en mode recherche
        var anchor = document.location.hash.substr().split('=');

        if (anchor[0] !== '#search') {
            // Le scroll vers le produit
            var element_id = anchor[1];
            if (element_id !== '' && element_id !== undefined && typeof (connected) == 'undefined') {
                $('html, body').animate({
                    scrollTop: $('#itm-' + element_id).offset().top - 120 // 120 hauteur du menu en scroll
                }, 500);
            }
        }
    }

    $(".switchLangBtn").on("click", function () {

        $(".switchLangWrapper").slideToggle();
    });

    var topAccount = $(".block_top_item.is_logged");
    var topCart = $("#cart_top");
    var topConnexion = $('.connexion_top');
    var topaCartDeatil = $('#show_top_cart');
    var menuShade = $('#shad_menu');

    $(topAccount).on('mouseenter touchstart', function (evt) {

        if (!topConnexion.hasClass("appear")) {

            $(this).addClass("underlined");
            evt.preventDefault();
            topConnexion.addClass("appear");
            topConnexion.stop().slideDown(400);
            topaCartDeatil.removeClass("appear");
        }
    });

    topAccount.on('mouseleave', function () {

        if (topConnexion.hasClass("appear")) {

            topConnexion.removeClass("appear");
            topConnexion.stop().slideUp(300);
            $(this).removeClass("underlined");
        }
    });

    topCart.on('mouseenter touchstart', function (evt) {
        evt.preventDefault();
        topCartAppear();
        $(this).unbind('touchstart');
    });

    topCart.on('mouseleave', function () {
        topCartDesappear();
    });

    $('#shad_menu').on('touchstart', function (evt) {
        topCartDesappear();
    });

    var menu_timeout = setTimeout(null);

    $(".main_menu_itm").not(".menu_itm_hover").on({
        mouseenter: function () {

            var mainmenu = $(".mainMenu");

            clearTimeout(menu_timeout);

            mainmenu.addClass("actif");
        },
        mouseleave: function () {

            menuItemClose();
        }
    });

    $(".menu_itm_hover").on("mouseenter touchstart", function (evt) {

        var mainmenu = $(".mainMenu");
        var submenu = $(".submenu_wrapper", this);
        $(".submenu_wrapper", !this).hide();
        clearTimeout(menu_timeout);

        if (!mainmenu.hasClass("actif")) {

            $(this).addClass("hovering");

            evt.preventDefault();

            mainmenu.addClass("actif");

            menu_timeout = setTimeout(function () {

                submenu.stop(true, true).fadeIn(1500, 'easeOutExpo');
            }, 250);
        } else {

            if (!$(this).hasClass("hovering")) {

                evt.preventDefault();
                $(this).addClass("hovering");
            }

            $(".submenu_wrapper").css("zIndex", 10);
            submenu.show();
            submenu.css("zIndex", 11);
        }
    });

    // La gestion des bannières dans le menu par rayon
    $('.ss_cat_name').on('mouseover', function() {
        var data_visuel = $(this).attr('data-visuel');
        // Si il y a un visuel attaché à cette sous categorie avlors on va l'afficher
        if (data_visuel) {
            var elt = $('#' + data_visuel);
            // Si l'élement n'est pas déjà actif on reset et on l'active
            if (!elt.hasClass('current')) {
                $('.visu_menu').removeClass('current');
                elt.addClass('current');
            }
        }
    });

    function menuItemClose(submenu) {

        clearTimeout(menu_timeout);

        if (submenu) {

            submenu.hide();
            submenu.css("zIndex", 10);
        }

        menu_timeout = setTimeout(function () {

            menuShade.removeClass('actif');
            $(".mainMenu").removeClass('actif');
        });
    }

    $(document).on("scroll", function () {

        menuItemClose($(".submenu_wrapper:visible"));
    });

    $('.menu_itm_hover').on('mouseleave', function () {

        menuItemClose($(".submenu_wrapper", this));
        $(this).removeClass("hovering");
    });

    if ($(".filtre_trigger").length > 0) {
        if ($(".triggered").length == 0) {
            $(".block_filters").hide();
            $(".filtre_trigger").removeClass("triggered");
        }
    }

    if($(".switchLangBtn").length > 0){
        $(".switchLangBtn").on({
            mouseenter: function () {
                $(".switchLangWrapper").fadeToggle();
            },
            mouseleave: function () {
                $(".switchLangWrapper").fadeToggle();
            }
        });
    }

    if($("#trigger_recherche").length > 0){
        $("#trigger_recherche").on('click touch', function (evt) {
            if($("#trigger_recherche").hasClass("searching") == false){
                $("#trigger_recherche").hide();
                $(".wrap_search_field").show(300);
                $(".wrap_search_field").addClass("searching");
            }
        });
    }

    if($(".wrap_search_field .close_it").length > 0){
        $(".close_it").on('click touch', function (evt) {
            $("#search_home").val("");
            $(".wrap_search_field").hide(300);
            $(".wrap_search_field").removeClass("searching");
            window.setTimeout(function () {
                $("#trigger_recherche").show();
            }, 300);
        });
    }

    if ($(".promo_swiper .swiper-slide").length > 1) {
        promoSwiper = new Swiper(".promo_swiper", {
            centeredSlides: true,
            slidesPerView: 1,
            autoplay: true,
            speed: 2000,
            loop: true,
        });
    }

    if ($(".swiper_looks .swiper-slide").length > 0) {

        var items = $(".swiper_looks .item_look");
        var items_tmp = [];
        var items_tmp_bloc = [];
        var nb_items = items.length;

        // On les affiche les portraits 2 par 2 et les paysage 1 par 1
        var i = 0;
        items.each(function (index) {
            var elt = $(this).clone();
            elt.removeClass('swiper-slide');
            // On va "regrouper" les bloc portrait et laisser seule les bloc paysage. Le tous en gardant le même ordre
            if (elt.data('format') === 'portrait') {
                items_tmp_bloc.push(elt);
            } else if (elt.data('format') === 'paysage') {
                items_tmp.push(elt)
            }

            // Si le nombre d'image par 2 est attent on transfère le tableau dans le tableau final
            if (items_tmp_bloc.length === 2) {
                items_tmp.push(items_tmp_bloc);
                // On vide le bloc
                items_tmp_bloc = [];
            }
        });

        $(".swiper_looks .item_look img").each(function (index) {
            var img_width = $(this).width();
            $(this).closest('.item_look').addClass(img_width.toString());
        });

        var swiper_look = $('.swiper_looks .swiper-wrapper');
        // On vide l'ancien swiper
        swiper_look.html('');
        // On va parcourir les image réunie
        $.each(items_tmp, function(index, value) {
            var bloc = $('<div>', {class: 'swiper-slide'}).clone();
            if (value.length > 1) {
                var length = value.length;
                for(var i = 0 ; i < length ; i++) {
                    value[i].appendTo(bloc);
                }
            } else {
                value.appendTo(bloc);
            }
            // On remplie avec les images réorganisées
            bloc.appendTo(swiper_look);
            return '';
        });

        lookSwiper = new Swiper(".swiper_looks", {
            centeredSlides: true,
            slidesPerView: 1.24,
            loop: true,
            loopAdditionalSlides: 0,
            spaceBetween: 10,
            navigation: {
                nextEl: '.swiper-btn.swiper-button-next',
                prevEl: '.swiper-btn.swiper-button-prev',
            }
        });

    lookSwiper.slideTo(2);
    }

    if($(".bank_left_side").length > 0){
        $(".wrapper_bloc_banque").on('click touch', function (evt) {
            $("#bloc_paypal").removeClass('actif');
            $(".wrapper_bloc_banque").addClass('actif');
        });

        $("#bloc_paypal").on('click touch', function (evt) {
            $(".wrapper_bloc_banque").removeClass('actif');
            $("#bloc_paypal").addClass('actif');
        });
    }

    // Chargement de la tarification
    if($("#tarification_load").length > 0){

        // Chargement des différentes tarification
        $.ajax({
            url: path_relative_root + 'ajax_tarification_list.php',
            type: 'post',
            dataType: 'json',
            success: function (res) {
                if (res !== []) {
                    var div = $('<div>', {'class': 'custom-select custom-select-footer'});
                    var select = $('<select id="country_tarif" name="country">');
                    $.each(res, function (key, val) {
                        var option = $("<option />", {value: key, text: val.pays_nom});
                        if (val.selected) {
                            option.prop('selected', true)
                        }
                        $(option).appendTo(select);
                    });
                    // Event sur le select lorsque l'on change de pays
                    select.on('change', function () {
                        var country_iso = $(this).val();
                        // On va envoyé au serveur que l'on change de pays
                        $.ajax({
                            url: path_relative_root + 'ajax_tarification_change_country.php',
                            type: 'post',
                            data: 'iso=' + country_iso,
                            success: function () {
                                location.reload();
                            }
                        });
                    });
                    select.appendTo(div);
                    div.appendTo($("#tarification_load"));
                    initCustomSelect('custom-select-footer');
                } else {
                    $("#tarification_load").css('display', 'none');
                }
            }
        });
    }

    if($('.cover-module .home-columns-container .slide_video').length) {
        //Re-application d'une with sur l'iframe pour éviter le bug des bordures au chargement de la page
        setTimeout(function() {
            $('.cover-module .home-columns-container .slide_video iframe').css('width', '100%');
        },200)
    }

    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function() {
        if($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
            $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
            $('.misc_cookiesinfo #shadCookie').removeClass('actif');
        }
    });
});

/**
 * Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
 */
function topCartAppear() {
    var show_top_cart = $('#show_top_cart');
    if (!show_top_cart.hasClass("appear")) {
        $("#cart_list").lionbars();
        show_top_cart.addClass("appear");
        $('.connexion_top').removeClass("appear");
        $("#cart_top").addClass("underlined");
        $('#shad_menu').addClass("actif rollPanier");
        bodyScrollLock();
    }
}

/**
 * Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
 */
function topCartDesappear() {
    var show_top_cart = $('#show_top_cart');
    if (show_top_cart.hasClass("appear")) {
        show_top_cart.removeClass("appear");
        $("#cart_top").removeClass("underlined");
        $('#shad_menu').removeClass("actif rollPanier");
        bodyScrollUnlock();
    }
}

function bodyScrollLock(){
    "use strict";

    $('body').css({
        'overflow' : 'hidden',
        'padding-right' : '17px'
    });
    $('#site_head_wrap').css({
        'padding-right' : '17px'
    });
}

function bodyScrollUnlock(){
    "use strict";

    $('body').css({
        'overflow' : 'inherit',
        'padding-right' : '0'
    });
    $('#site_head_wrap').css({
        'padding-right' : '0'
    });
}

function btnFliterTrigger(evt) {
    "use strict";

    if ($(".filtre_trigger").length > 0) {
        $(".block_filters").fadeToggle().css('display', 'flex');
        $(".filtre_trigger").toggleClass("triggered");
    }
}

function btnLoaderTrigger(evt) {

    "use strict";

    var element = $(this);
    var loader = $(this).next(".loader");

    if (loader.length > 0) {

        evt.preventDefault();

        loader.removeAttr("style").parent().addClass("loading");

        setTimeout(function () {
            loader.parent().removeClass("loading");
        }, 1200);

        var href = $(this).attr("href");
        if (href && href != '#' && href != 'javascript:void(0)') {
            window.location.assign($(this).attr("href"));
        }

        // Dans le cas où il y a plusieurs bouton  en loading
        window.setTimeout(function() {$('.form_submit.loading').removeClass('loading')}, 5000);
    }
}

/**
 * New functions (they are supposed to be at least a little bit cleaner than legacy code)
 */
// Below lies the legacy code (enter at your own risk)
function checkdate(d, m, y) {

    "use strict";

    return m > 0 && m < 13 && y > 0 && y < 32768 && d > 0 && d <= (new Date(y, m, 0)).getDate();
}

var nav_UA = window.navigator.userAgent.split('/'),
    nav_ver = parseFloat(nav_UA.pop()),
    nav_name = nav_UA.pop();

if ((nav_name.indexOf('safari') !== -1 && nav_ver <= 525) || (nav_name.indexOf('firefox') !== -1 && nav_ver === 1.8)) {
    function _dom_trackActiveElement(evt) {

        "use strict";

        if (evt && evt.target) {
            document.activeElement = evt.target == document ? null : evt.target;
        }
    }

    function _dom_trackActiveElementLost(evt) {

        "use strict";

        document.activeElement = null;
    }

    if (!document.activeElement) {
        document.addEventListener("focus", _dom_trackActiveElement, true);
        document.addEventListener("blur", _dom_trackActiveElementLost, true);
    }
}
/**/
var id_select = '';

// -----------------------------------------------------------------

// Définit les classes initiales pour les elements d'un formulaire
function setInitialClassName(elementIdArray) {

    "use strict";

    for (i = 0; i < elementIdArray.length; i++) {

        var elementInput = document.getElementById(elementIdArray[i]);
        var elementLabel = document.getElementById(elementIdArray[i] + 'Label');

        if (elementInput && elementInput.type != 'hidden') {

            elementInput.className = 'inputForm';
            if (elementLabel) {

                elementLabel.className = 'labelForm';
            }
        }
    }
}

// Définit les classes initiales pour les elements de date d'un formulaire
function setInitialDateClassName(elementIdArray) {

    "use strict";

    var elementInputDay, elementInputMonth, elementInputYear, elementLabel;

    for (i = 0; i < elementIdArray.length; i++) {

        elementInputDay = document.getElementById(elementIdArray[i] + 'J');
        elementInputMonth = document.getElementById(elementIdArray[i] + 'M');
        elementInputYear = document.getElementById(elementIdArray[i] + 'A');
        elementLabel = document.getElementById(elementIdArray[i] + 'Label');

        if (elementInputDay && elementInputDay.type != 'hidden'
            && elementInputMonth && elementInputMonth.type != 'hidden'
            && elementInputYear && elementInputYear.type != 'hidden') {

            elementInputDay.className = 'inputForm';
            elementInputMonth.className = 'inputForm';
            elementInputYear.className = 'inputForm';
            if (elementLabel) {

                elementLabel.className = 'labelForm';
            }
        }
    }
}

// Vérification d'un bouton radio
function verif_radio(elementID, fieldname) {

    "use strict";
    if (elementID == 'clientCiviliteM' || elementID == 'clientCiviliteMme' || elementID == 'clientCiviliteMlle') {

        elementID = 'clientCivilite';
    }

    var objElement = document.getElementsByName(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var trouve = false;
    var t_error = null;

    $(objElement).removeData('data-error-text').removeAttr('data-error-text');

    if (elementID) {

        $("input[name='" + elementID + "']").each(function (idx) {

            if ($(this).is(':checked') == true) {

                objElement.className = 'inputForm';
                trouve = true;
            }
        });

        if (!trouve) {

            if (elementID == 'clientCivilite') {

                t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_civilite&arg2=' + fieldname);
            } else {

                t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
            }

            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        } else {

            $(objElement).removeClass('inputErr');
            $(objLabel).removeClass('labelErr');
        }
    }
    return true;
}

function verif_price(e, t) {

    "use strict";

    var a = document.getElementById(e),
        r = document.getElementById(e + "Label"),
        l = (document.getElementById("mes_err_fieldset"), document.getElementById("erreur_msg"));

    if ($(a).removeData("data-error-text").removeAttr("data-error-text"), "" == a.value) return t_error = '<p class="title_toolltip">' + ajax_file("ajax_sprintf.php?arg1=" + t) + " :</p>" + ajax_file("ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + t), $(a).data("data-error-text", t_error), l.innerHTML = t_error, a.className = "inputErr", r.className = "labelErr", !1;

    var n = validate_price(a.value);

    return 0 == n ? (t_error = '<p class="title_toolltip">' + ajax_file("ajax_sprintf.php?arg1=" + t) + " :</p>" + ajax_file("ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + t), $(a).data("data-error-text", t_error), l.innerHTML = t_error, a.className = "inputErr", r.className = "labelErr", !1) : (a.className = "inputForm", r.className = "labelForm", !0)
}

function validate_price(e) {
    return /^[\d\.,]+$/.test(e)
}

// Vérificartion d'une case coché
function verif_checkbox(elementName, fieldname) {
    var objElement = document.getElementsByName(elementName);
    var objLabel = document.getElementById(elementName.slice(0, -2) + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (elementName) {
        objElement.className = 'inputForm';
        objLabel.className = 'labelForm';
        for (i = 0; i < objElement.length; i++)
            if (objElement[i].checked == true) {
                objElement.className = 'inputForm';
                objLabel.className = 'labelForm';
                return true;
            }
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    return true;
}

function verif_checkbox_index(elementName, fieldname, pIndex) {
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (document.prodForm.visuStatutDetail1) {
        if (document.prodForm.visuStatutDetail1[0].checked == true) {
            return true;
        }
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_photo_validate');
        return false;
    } else {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_photo_validate');
        return false;
    }
}

function verif_zip(elementZipID, elementCountryID, fieldname, nb) {
    var objZipElement = document.getElementById(elementZipID);
    var objCountryElement = document.getElementById(elementCountryID);
    var objLabel = document.getElementById(elementZipID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');

    var idPays = objZipElement.value.substr(0, 2);

    if (objCountryElement.value == "DO" && idPays != '97') {

        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_zip_special_1&arg2=' + fieldname + '&arg3=97');
        $(objZipElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    } else if (objCountryElement.value == "MO" && idPays != '98') {

        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_zip_special_1&arg2=' + fieldname + '&arg3=98');
        $(objZipElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    } else if (objCountryElement.value == "TO" && idPays != '97' && idPays != '98') {

        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_zip_special_1&arg2=' + fieldname + '&arg3=97ou98');
        $(objZipElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    } else if ((objCountryElement.value != "DO" && objCountryElement.value != "TO" && objCountryElement.value != "MO") && (idPays == '97' || (idPays == '98' && objCountryElement.value != "FR"))) {

        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_zip_special_2&arg2=' + fieldname + '&arg3=' + idPays);
        $(objZipElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    }

    $(objZipElement).removeClass('inputForm');
    $(objLabel).removeClass('labelErr');
    return true;
}

function verif_num(elementID, fieldname, nb) {
    var flg = 0;
    var num = "0123456789.,";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var point = 0;
    var virgule = 0;

    if (objElement.value == "") {
        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');

        return false;
    } else {
        if (objElement.value.length < nb) {
            erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_at_least_x_digits&arg2=color:red;&arg3=' + fieldname + '&arg4=' + nb);
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_wrong_input_num&arg2=' + fieldname);
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            flg = 0;
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (test == '.') {
                point = point + 1;
            }
            if (test == ',') {
                virgule = virgule + 1;
            }
        }
        var tot = point + virgule;
        if (point > 1 || virgule > 1 || tot > 1) {
            erreurText.innerHTML = erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_wrong_input_num&arg2=' + fieldname);
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            flg = 0;
            return false;
        }

    }
    $(objElement).removeClass('inputErr');
    $(objElement).removeClass('labelErr');
    return true;
}

function verif_portable(elementID, fieldname) {
    return verif_telephone(elementID, fieldname, true);
}

function verif_telephone(elementID, fieldname, is_mobile) {

    var objElementHidden = document.getElementById(elementID + 'Hidden');
    var objElement = document.getElementById(elementID);
    var erreurText = document.getElementById('erreur_msg');

    if (objElementHidden !== null) {

        var value = $(objElement).intlTelInput("getNumber");
        var type = $(objElement).intlTelInput("getNumberType");

        if (value.length == 12 && value.substr(1, 3) == '337') {

            if (elementID === 'clientTel') {

                $('#clientMobileHidden').val(value);
            } else if (elementID == 'telephone') {

                $('#mobileHidden').val(value);
            } else if (elementID == 'billtelephone') {

                $('#billmobileHidden').val(value);
            } else if (elementID == 'candidatTel') {

                $('#candidatTelHidden').val(value);
            }
        } else {
            if ($(objElement).intlTelInput("isValidNumber") !== false && (type === 0 || type === 2 || type === 3 || type === 6)) {

                $(objElementHidden).val(value);
            } else if ($(objElement).intlTelInput("isValidNumber") !== false && type === 1) {

                if (elementID === 'clientTel') {

                    $('#clientMobileHidden').val(value);
                } else if (elementID == 'telephone') {

                    $('#mobileHidden').val(value);
                } else if (elementID == 'billtelephone') {

                    $('#billmobileHidden').val(value);
                } else if (elementID == 'candidatTel') {

                    $('#candidatTelHidden').val(value);
                }
            }
        }

        if ($(objElement).intlTelInput("isValidNumber") === false) {

            $(objElement).addClass('inputErr');

            return false;
        }

        $(objElement).removeClass('inputErr');

        return true;
    }

    if ($.trim($(objElement).val()).length > 0) {

        if ($(objElement).intlTelInput("isValidNumber") === false || ((is_mobile && $(objElement).intlTelInput("getNumberType") === 0)) || (is_mobile == false && $(objElement).intlTelInput("getNumberType") === 1)) {

            if (is_mobile && $(objElement).intlTelInput("getNumberType") === 0) {

                erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg2=' + fieldname + '_mobile') + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_mobile&arg2=' + fieldname);
            } else {

                erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg2=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_phone&arg2=' + fieldname);
            }

            return false;
        } else {

            $(objElementHidden).val($(objElement).intlTelInput("getNumber"));
        }

        return true;
    }

    $(objElement).addClass('inputErr');

    return false;
}

function verif_ref_interne(elementID, fieldname) {
    var flg = 0;
    var alphanum = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 9)) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=9');
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length < 9) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=9&arg3=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length > 14) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_ref_14_chars&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_alphanum_codepromo(elementID, fieldname) {
    var flg = 0;
    var alphanum = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ\,\-_/\.<>()%:;";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 4)) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=4');
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length < 4) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=4&arg3=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_empty(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value == "") {
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        erreurText.innerHTML = t_error;
        $(objElement).data('data-error-text', t_error);
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        $(objElement).removeClass('inputForm');
        $(objLabel).removeClass('labelForm');
        return false;
    }
    $(objElement).addClass('inputForm');
    $(objLabel).addClass('labelForm');
    $(objElement).removeClass('inputErr');
    $(objLabel).removeClass('labelErr');
    return true;
}

function verif_alphanum(elementID, fieldname, isMin, length) {
    var flg = 0;
    var alphanum = decodeURIComponent("%C2%B0") + "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ\,\'\"\-_/\.<>\n\r()%:; " + decodeURIComponent("%C3%A2%C3%A0%C3%A4%C3%A9%C3%A8%C3%AB%C3%AA%C3%AC%C3%AF%C3%AE%C3%B6%C3%B4%C3%B2%C3%B9%C3%BC%C3%BB%C3%A7&%C3%84%C3%82%C3%80%C3%89%C3%88%C3%8B%C3%8A%C3%8C%C3%8F%C3%8E%C3%96%C3%94%C3%92%C3%99%C3%9C%C3%9B%C3%87");
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value == "") {
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 3)) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=3');
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        }
        if (isMin == false && objElement.value.length < 3) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0) {
                flg++;
            }
        }
        if (flg > 0) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            flg = 0;
            return false;
        }
        if (isMin == true && objElement.value.length > length) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_too_long_alphanum&arg2=' + fieldname + '&arg3=' + length);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        }
    }
    $(objElement).removeClass('inputErr');
    $(objLabel).removeClass('labelErr');
    return true;
}

function verif_alphanumadresse(elementID, fieldname, isMin, length) {
    var flg = 0;
    var alphanum = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ\,\'\"_/\.<>\n\r()%:; " + decodeURIComponent("%C3%A2%C3%A0%C3%A9%C3%A8%C3%AB%C3%AAi%C3%AF%C3%AE%C3%B6%C3%B4%C3%B9%C3%BC%C3%BB%C3%A7%26%C3%84%C3%82%C3%80%C3%89%C3%88%C3%8B%C3%8AI%C3%8F%C3%8E%C3%96%C3%94%C3%99%C3%9C%C3%9B%C3%87%3F!%E2%82%AC-");
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value == "") {
        t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        objElement.className = 'inputErr';
        if (objLabel)
            objLabel.className = 'labelErr';
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 3)) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=3');
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            if (objLabel)
                objLabel.className = 'labelErr';
            return false;
        }
        if (isMin == false && objElement.value.length < 3) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            if (objLabel)
                objLabel.className = 'labelErr';
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            if (objLabel)
                objLabel.className = 'labelErr';
            flg = 0;
            return false;
        }
        if (isMin == true && objElement.value.length > length) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_too_long_alphanum&arg2=' + fieldname + '&arg3=' + length);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    }
    objElement.className = 'inputForm';
    if (objLabel)
        objLabel.className = 'labelForm';
    return true;
}

function verif_textarea(elementID, fieldname) {
    var flg = 0;
    var alphanum = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,'\"\\n\r-_/.()%:; éééééiééééééé";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'textareaErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 3)) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=3');
            objElement.className = 'textareaErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length < 3) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname);
            objElement.className = 'textareaErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            objElement.className = 'textareaErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'textarea';
    objLabel.className = 'labelForm';
    return true;
}

function verif_textarea2(elementID, fieldname) {
    var flg = 0;
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'textareaErr';
        objLabel.className = 'labelErr';
        return false;
    }
    if (objElement.value.length < 3) {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname);
        objElement.className = 'textareaErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElement.className = 'textarea';
    objLabel.className = 'labelForm';
    return true;
}

function verif_mail(elementID, fieldname, is_spe) {

    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var mail = new RegExp("^[a-z0-9_-]+([.]?[a-z0-9_-]{1,})*@([a-z0-9_-]{2,}[.])+[a-z]{2,4}$", "gi");

    path = '';
    if (typeof (path_relative_root) != 'undefined')
        path += path_relative_root;

    if (mail.exec(objElement.value) == null) {
        if (typeof (is_spe) == 'undefined') {
            var translations = ajax_file(path + 'ajax_sprintf.php?arg0=%s|%s' +
                '&arg1=' + fieldname +
                '&arg2=js_wrong_input_mail'
            );
            translations = translations.split("|");
            type_champ = translations[0];
            type_message = translations[1];

            erreurText.innerHTML = '<p class="title_toolltip">' + type_champ + ' :</p>' + type_message;
        }
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;

    }
    $(objElement).removeClass('inputErr');
    $(objLabel).removeClass('labelErr');
    return true;
}

function verif_alpha(elementID, fieldname) {
    var flg = 0;
    var alphanum = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,'\"-_/.ééÈèËëÊêÔôÎîÛûÂâÏïÄÖöäÜüÀàÙùÇç&n " + decodeURIComponent("%C3%A0%C3%A9%C3%A8%C3%AB%C3%AAi%C3%AF%C3%AE%C3%B6%C3%B4%C3%B9%C3%BC%C3%BB%C3%A7%C3%A4%C3%84%C3%82%C3%80%C3%89%C3%88%C3%8B%C3%8AI%C3%8F%C3%8E%C3%96%C3%94%C3%99%C3%9C%C3%9B%C3%87%C2%A0 ");
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value == "") {
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 2)) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=2');
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        }
        if (objElement.value.length < 2) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=2&arg3=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alpha&arg2=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            flg = 0;
            return false;
        }
    }
    $(objElement).removeClass('inputErr');
    $(objLabel).removeClass('labelErr');
    return true;
}

function verif_format_date(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value != '') {
        var regDateVente = new RegExp("^(\\d{2})/(\\d{2})/(\\d{2})$", "gi");
        if (regDateVente.exec(objElement.value) == null) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_1&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    } else {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_2&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_format_date2(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value != "") {
        var regDateVente = new RegExp("^(\\d{2})/(\\d{2})/(\\d{4})$", "gi");
        if (regDateVente.exec(objElement.value) == null) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_1&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    } else {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_2&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_format_date_select(elementID, fieldname) {
    var objElementJ = document.getElementById(elementID + 'J');
    var objElementM = document.getElementById(elementID + 'M');
    var objElementA = document.getElementById(elementID + 'A');
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElementJ.value == "" || objElementM.value == "" || objElementA.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        objElementJ.className = 'inputErr';
        objElementM.className = 'inputErr';
        objElementA.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElementJ.className = 'inputForm';
    objElementM.className = 'inputForm';
    objElementA.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_format_majorite(elementID, fieldname) {
    var objElementJ = document.getElementById(elementID + 'J');
    var objElementM = document.getElementById(elementID + 'M');
    var objElementA = document.getElementById(elementID + 'A');
    var date = new Date();
    var annee = date.getFullYear();
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElementA.value > (annee - 18)) {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_majorite');
        objElementJ.className = 'inputErr';
        objElementM.className = 'inputErr';
        objElementA.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElementJ.className = 'inputForm';
    objElementM.className = 'inputForm';
    objElementA.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_selected(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement) {
        var objLabel = document.getElementById(elementID + 'Label');
        if (objElement.value == "") {
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_select&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        } else {
            objElement.className = 'inputForm';
            objLabel.className = 'labelForm';
            return true;
        }
    }
    return true;
}

function verif_selected_date(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var objElementJ = document.getElementById(elementID + 'J');
    var objElementM = document.getElementById(elementID + 'M');
    var objElementA = document.getElementById(elementID + 'A');
    if (objElementJ && objElementM && objElementA) {


        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        var error = false;
        if (objElementJ.value == "") {
            error = true;
            $(objElementJ).addClass('inputErr');
        } else {
            $(objElementJ).removeClass('inputErr');
        }
        if (objElementM.value == "") {
            error = true;
            $(objElementM).addClass('inputErr');
        } else {
            $(objElementM).removeClass('inputErr');
        }
        if (objElementA.value == "") {
            error = true;
            $(objElementA).addClass('inputErr');
        } else {
            $(objElementA).removeClass('inputErr');
        }
        if (!error) {
            $(objLabel).removeClass('labelErr');
            return true;
        } else {
            $(objLabel).addClass('labelErr');
            return false;
        }
    }
    return true;
}

function verif_selected2(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement) {
        var objLabel = document.getElementById(elementID + 'Label');
        if (objElement.value == "") {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_select&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        } else {
            objElement.className = 'inputForm';
            objLabel.className = 'labelForm';
            return true;
        }
    } else {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_select&arg2=' + fieldname);
        return false;
    }
    return true;
}

function verif_categ(formID) {
    var objForm = document.getElementById(formID);
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objForm.prodCadeau) {
        if (objForm.prodCadeau.checked == false && !objForm.prodCateg_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_1');
            return false;
        }
        if (objForm.prodCadeau.checked == false
            && objForm.prodCateg_0.selectedIndex
            && !objForm.prodSousCateg_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_1');
            return false;
        }
        if (objForm.prodCadeau.checked == false
            && objForm.prodCateg_0.selectedIndex
            && objForm.prodSousCateg_0.selectedIndex
            && !objForm.prodObjet_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_1');
            return false;
        }
    } else {
        if (!objForm.prodCateg_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_2');
            return false;
        }
        $errTMP = false;
        for (x = 0; x <= 4; x++) {
            if (eval("objForm.prodCateg_" + x + ".selectedIndex"))
                if (!eval('objForm.prodObjet_' + x))
                    $errTMP = true;
                else if (!eval('objForm.prodObjet_' + x + '.selectedIndex'))
                    $errTMP = true;
        }
        if ($errTMP == true) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_3');
            return false;
        }
    }
    return true;
}

var indexImg = 0;

function nextImage(elementID, imgPath) {
    if (imgPath.length > 0) {
        indexImg++;
        if (indexImg >= imgPath.length)
            indexImg = 0;
        MM_swapImage(elementID, '', imgPath[indexImg], 1);
    }
}

function prevImage(elementID, imgPath) {
    if (imgPath.length > 0) {
        indexImg--;
        if (indexImg < 0)
            indexImg = imgPath.length - 1;
        MM_swapImage(elementID, '', imgPath[indexImg], 1);
    }
}

function NewWindow(mypage, myname, w, h, scroll) {
    LeftPosition = (screen.width) ? (screen.width - w) / 2 : 0;
    TopPosition = (screen.height) ? (screen.height - h) / 2 : 0;
    settings =
        'height=' + h + ',width=' + w + ',top=' + TopPosition + ',left=' + LeftPosition + ',scrollbars=' + scroll + ',resizable,';
    win = window.open(mypage, myname, settings);
}

function preg_replace(array_pattern, array_pattern_replace, my_string) {
    var new_string = String(my_string);
    for (i = 0; i < array_pattern.length; i++) {
        var reg_exp = RegExp(array_pattern[i], "gi");
        var val_to_replace = array_pattern_replace[i];
        new_string = new_string.replace(reg_exp, val_to_replace);
    }
    return new_string;
}

function verif_num_bandeau(elementID, fieldname) {
    var flg = 0;
    var num = "0123456789.,";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    var point = 0;
    var virgule = 0;
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_num_bandeau&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_etage(elementID, fieldname, nb) {
    var flg = 0;
    var num = "0123456789rdcRDC.";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    var point = 0;
    var virgule = 0;
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        if (objElement.value.length > nb) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=' + nb + '&arg3=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_num_rdc&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verifSame(field1, field2, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (document.getElementById(field1).value != document.getElementById(field2).value) {
        var txt_lang = (fieldname == 'login_email_verif') ? 'js_same_email_confirm' : 'js_same_mdp_confirm';
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=' + txt_lang + '&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    }
    $(objElement).removeClass('inputErr');
    $(objLabel).removeClass('labelErr');
    return true;
}

function isClosedDay(day, month) {
    var array_closed_array = new Array('1/1', '25/4', '1/5', '8/5', '2/6', '13/6', '14/7', '15/8', '1/11', '11/11', '25/12', '26/12');
    return array_closed_array.in_array(day + '/' + month);
}

(function () {

    'use strict';

    function in_array(p_val) {

        for (var i = 0, l = this.length; i < l; i++) {

            if (this[i] == p_val) {

                return true;
            }
        }

        return false;
    }

    Object.defineProperty(Array.prototype, 'in_array', {
        configurable: true,
        enumerable: false,
        writable: false,
        value: in_array
    });
}());

function changeVisu(field, path, evt) {

    var new_img, target, current;

    field = document.getElementById(field);

    if (evt) {

        target = evt.target.parentElement;
        current = target.parentElement.getElementsByClassName("current");

        target.classList.add("current");

        Array.prototype.slice.call(current).forEach(function (elm) {

            if (elm !== target) {

                elm.classList.remove("current");
            }
        });
    }

    if (field && field.parentElement) {

        new_img = new Image();

        new_img.id = field.id;
        new_img.className = field.className;

        new_img.addEventListener('load', function () {

            this.alt = field.alt;

            this.setAttribute('width', this.naturalWidth);
            this.setAttribute('height', this.naturalHeight);

            if (field.parentElement) {

                field.parentElement.appendChild(this);
                field.parentElement.removeChild(field);
            }
        });

        new_img.src = path;
    }

    // Simpler way doesn't seem to work well with lazyloader
    // return document.getElementById(field).src = path;
}

function getPos(obj) {
    var pos = {x: obj.offsetLeft || 0, y: obj.offsetTop || 0};
    while (obj = obj.offsetParent) {
        pos.x += obj.offsetLeft || 0;
        pos.y += obj.offsetTop || 0;
    }
    return pos;
}

function closeInfobulle(id) {
    if (!jQuery.browser.msie) $("#" + id).fadeOut(400);
    else document.getElementById(id).style.display = 'none';
    document.getElementById(id).style.zIndex = '0';
}

var one_time = false;

function openInfobulle(id, type, elem) {
    window.clearTimeout(timer);
    $("#bloc_erreur").css('display', 'none');
    if (!jQuery.browser.msie) $("#bloc_erreur").stop(false, true).fadeOut();
    if (!jQuery.browser.msie) $("#" + id).fadeIn(400);
    else document.getElementById(id).style.display = 'block';
    if (typeof (type) != 'undefined') {
        var margin_spe_left = 0;
        var margin_spe_top = 0;
        if (elem.nodeName == 'SELECT') {
            id = elem.id.substr(0, elem.id.length - 1);
            if (id == 'clientMailAuth' || id == 'clientPwdAuth' || id == 'mailLost') {
                var txt_spe = document.getElementById(id + 'ErrErr').innerHTML;
                margin_spe_left = 300;
                if (id == 'clientPwdAuth')
                    margin_spe_top = 10;
                infos_alias
            } else if (type == 'banque')
                var txt_spe = document.getElementById('infos_carte').innerHTML;
            else if (type == 'alias_banque')
                var txt_spe = document.getElementById('infos_alias').innerHTML;
            else
                var txt_spe = document.getElementById(id + 'Err').innerHTML;
        } else {
            if (elem.id == 'clientMailAuth' || elem.id == 'clientPwdAuth' || elem.id == 'mailLost') {
                if (document.getElementById(elem.id + 'ErrErr'))
                    var txt_spe = document.getElementById(elem.id + 'ErrErr').innerHTML;
                if (elem.id == 'mailLost')
                    margin_spe_left = -350;
                else
                    margin_spe_left = -340;
                if (elem.id == 'clientPwdAuth')
                    margin_spe_top = 41;
                else
                    margin_spe_top = 4;
            } else if (type == 'banque')
                var txt_spe = document.getElementById('infos_carte').innerHTML;
            else if (type == 'alias_banque')
                var txt_spe = document.getElementById('infos_alias').innerHTML;
            else
                var txt_spe = document.getElementById(elem.id + 'Err').innerHTML;
        }
        document.getElementById('erreur_msg').innerHTML = txt_spe;
        pos = getPos(elem);
        document.getElementById('bloc_erreur').style.position = 'absolute';
        if (type == 'banque') {
            margin_spe_left -= 42;
            margin_spe_top += 30;
        } else if (type == 'alias_banque') {
            margin_spe_left -= 42;
            margin_spe_top += 15;
        }
        document.getElementById('bloc_erreur').style.top = pos.y - (document.getElementById('bloc_erreur').offsetHeight + margin_spe_top - 2) + 'px';
        pos_left_margin = getPos(document.getElementById('content'));
        left_add = 0;
        if (document.getElementById('wrapper_anniversaire_jour1')) {
            left_add = document.getElementById('bloc_erreur').offsetWidth;
            left_add -= 35;
        }
        document.getElementById('bloc_erreur').style.left = (pos.x + (elem.offsetWidth - 8) + margin_spe_left + 27 - left_add) + 'px';
        if (type == 'banque' || type == 'alias_banque') {
            document.getElementById('bloc_erreur').style.width = '280px';
        } else {
            document.getElementById('bloc_erreur').style.width = '280px';
        }
        document.getElementById('bloc_erreur').style.zIndex = '10000';
    }
}

function OverPopup(elem, msg, type, is_vp) {
    clearTimeout(timer);

    if (type == 'visible') {
        if (typeof (is_vp) != 'undefined' && is_vp != 'cmpid_adwords') {
            $("#bloc_erreur_vp").css('display', 'none');
            if (!jQuery.browser.msie) {
                $("#bloc_erreur_vp").stop(false, true).fadeOut();
            }
            if (!jQuery.browser.msie) {
                $("#bloc_erreur_vp").fadeIn(400);
            } else {
                document.getElementById('bloc_erreur_vp').style.display = 'block';
            }
        } else {
            if (!document.getElementById('formAnniversaire') || (document.getElementById('formAnniversaire') && elementID != 'newsletterMailv' && elementID != 'newsletterMailInscrit')) {
                pos = getPos(elem);
                document.getElementById('erreur_msg').innerHTML = msg;
                if (!jQuery.browser.msie) {
                    $("#bloc_erreur").stop(true, true).fadeIn(400);
                } else {
                    document.getElementById('bloc_erreur').style.display = 'block';
                }
                document.getElementById('bloc_erreur').style.position = 'absolute';
                pos_left_margin = -13;
                var left_add = 17;
                if (document.getElementById('wrapper_anniversaire_jour1')) {
                    left_add = document.getElementById('bloc_erreur').offsetWidth;
                    left_add -= 35;
                }
                if (document.getElementById('formAnniversaire')) {
                    left_add = $('#erreur_msg').width() - 10;
                    $(".bottom_tooltip").attr('align', 'right');
                }
                document.getElementById('bloc_erreur').style.left = (pos.x + (elem.offsetWidth - 8) - pos_left_margin - left_add) + 1 + 'px';
                if (is_vp === 'cmpid_adwords') {
                    var clone = $('#bloc_erreur').clone(),
                        c_pos = {
                            top: $(elem).offset().top - $(elem).offsetParent().offset().top - $('#bloc_erreur').height() + 4,
                            left: $(elem).offset().left - $(elem).offsetParent().offset().left - $('#bloc_erreur').innerWidth() + ($(elem).width() * 2) + 6
                        };
                    $('#bloc_erreur').remove();
                    $(elem).after(clone);
                    $('#bloc_erreur').find('td:nth-child(2)').children('img').css({
                        'float': 'right',
                        'margin': '0 7px'
                    });
                    $('#bloc_erreur').css({
                        'position': 'absolute',
                        'top': c_pos.top,
                        'left': c_pos.left,
                        'z-index': '99999',
                        'opacity': '1'
                    });
                } else {
                    document.getElementById('bloc_erreur').style.top = (pos.y - document.getElementById('bloc_erreur').offsetHeight) + 'px';
                }
                if (elem.id == "departement_naissance") {
                    $('#bloc_erreur').css({"width": 180, "marginTop": 63, "marginLeft": -310});
                }
                document.getElementById('bloc_erreur').style.zIndex = '99999';
            }
        }
    } else {
        if (typeof (is_vp) != 'undefined' && is_vp != 'cmpid_adwords') {
            if (!jQuery.browser.msie) {
                $("#bloc_erreur_vp").fadeOut(400);
            } else {
                document.getElementById('bloc_erreur_vp').style.display = 'none';
            }
            document.getElementById('bloc_erreur_vp').style.zIndex = '0';
        } else {
            if (!jQuery.browser.msie) {
                $("#bloc_erreur").stop(true, true).fadeOut(400);
            } else {
                document.getElementById('bloc_erreur').style.display = 'none';
            }
            document.getElementById('bloc_erreur').style.zIndex = '0';
        }
    }
}


function getXY(obj) {
    var curleft = 0;
    var curtop = obj.offsetHeight + 5;
    var border;
    if (obj.offsetParent) {
        do {
            if (getStyle(obj, 'position') == 'relative') {
                if (border = _pub.getStyle(obj, 'border-top-width')) curtop += parseInt(border);
                if (border = _pub.getStyle(obj, 'border-left-width')) curleft += parseInt(border);
            }
            curleft += obj.offsetLeft;
            curtop += obj.offsetTop;
        }
        while (obj = obj.offsetParent)
    } else if (obj.x) {
        curleft += obj.x;
        curtop += obj.y;
    }
    return {'x': curleft, 'y': curtop};
}

/**
 * Returns the specified computed style on an object.
 * @param {HTMLObject} obj HTML Object
 * @param {String} styleProp Property name.
 * @return {Mixed} Computed style on object.
 */
function getStyle(obj, styleProp) {
    if (obj.currentStyle)
        return obj.currentStyle[styleProp];
    else if (window.getComputedStyle)
        return document.defaultView.getComputedStyle(obj, null).getPropertyValue(styleProp);
}

/* pour les questions du panier panier */
function fadePanier(id, nbelem) {

    for (var i = 1; i <= nbelem; i++) {

        if (id != i && $("#texte_question_" + i).css('display') == 'block') {

            $("#texte_question_" + i).slideUp(400);
            $("#intitule_question_" + i).removeClass('actif');
        }
    }

    if ($("#texte_question_" + id).css('display') != 'block') {

        $("#texte_question_" + id).slideDown(400);
        $("#texte_question_" + id).addClass('actif');
        $("#intitule_question_" + id).addClass('actif');
    } else {

        $("#texte_question_" + id).slideUp(400);
        $("#texte_question_" + id).removeClass('actif');
        $("#intitule_question_" + id).removeClass('actif');
    }
}

/* pour les tableaux du guide des tailles */
function fadeTaille(id, nbelem) {

    for (var i = 1; i <= nbelem; i++) {

        if (id != i && $("#content_quide_" + i).css('display') == 'block') {

            $("#content_quide_" + i).slideUp(400);
            $("#intitule_quide_" + i).removeClass('actif');
        }
    }

    if ($("#content_quide_" + id).css('display') != 'block') {

        $("#content_quide_" + id).slideDown(400);
        $("#content_quide_" + id).addClass('actif');
        $("#intitule_quide_" + id).addClass('actif');
    } else {

        $("#content_quide_" + id).slideUp(400);
        $("#content_quide_" + id).removeClass('actif');
        $("#intitule_quide_" + id).removeClass('actif');
    }
}

function fadeHelp(id, nbelem) {
    for (var i = 1; i <= nbelem; i++) {
        if (id != i && $("#answer_" + i).css('display') == 'block') $("#answer_" + i).slideUp(400);
    }
    if ($("#answer_" + id).css('display') != 'block') $("#answer_" + id).slideDown(400);
    else $("#answer_" + id).slideUp(400);
}

function show_popup(elem) {
    openMultiShad(elem);
}

function close_popup(elem) {
    var $modbox = $("#" + elem);
    var $shad = $("#shad");
    $shad.attr('onclick', '');
    $shad.animate({opacity: 0}, 350, function () {
        $shad.css({display: 'none', opacity: ''}).data('opened_lb', 0);
    });
    $modbox.css({display: 'none'});

    bodyScrollUnlock();
}

/********showblocform********/
function showBlocForm(id, type) {

    clearTimeout(timer);

    if ($('#bloc_erreur').css('display') == 'table' || $('#bloc_erreur').css('display') == 'block') {

        $('#bloc_erreur').css('display', 'none');
    }

    if (id == 'is_not_client') {

        $(".wrapper_login.left").addClass('actif');
        $(".wrapper_login.right").removeClass('actif');

        if ($("#bloc_lost_password").is(':visible')) {

            setTimeout(reloadBlocLogin, 100);
        }
    } else if (id == 'is_client') {

        $(".wrapper_login.left").removeClass('actif');
        $(".wrapper_login.right").addClass('actif');
    }
}

/*********/
/********affichelostpassword********/
var afficheLostPassword = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');

    evt.preventDefault();

    signIn.animate({
        opacity: 0
    }, 600, function () {

        signIn.css('display', 'none');

        passWd.css('display', '').animate({
            opacity: 1
        }, 600);
    });
};

/*********/
/********reloadbloclogin********/
var reloadBlocLogin = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');

    evt.preventDefault();

    passWd.animate({
        opacity: 0
    }, 600, function () {

        passWd.css('display', 'none');

        signIn.css('display', '').animate({
            opacity: 1
        }, 600);
    });
};

/*********/

function afficheLostPasswordTop() {

    if ($('#bloc_erreur').is(':visible')) {

        $('#bloc_erreur').css('display', 'none');
    }

    $("#is_client_form").hide();
    $("#lost_password_top").css({display: "block", opacity: 0}).fadeIn(600);
}

function reloadBlocLoginTop() {

    if ($('#bloc_erreur').is(':visible')) {

        $('#bloc_erreur').css('display', 'none');
    }

    $("#lost_password_top").hide();
    $("#returning_customer_top").fadeIn(600);
}

function toggleFullScreen() {
    var video = document.getElementById("video_spa");
    if (video.requestFullScreen) {
        video.requestFullScreen();
    } else if (video.webkitRequestFullScreen) {
        video.webkitRequestFullScreen();
    } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen();
    }
}

function show_lightbox_3xcb() {
    $('#shad').fadeTo("slow", 0.8).queue(function () {
        $(this).css('display', 'block');
        $('#popup_3xcb').slideDown("slow");
        $(this).bind('click', close_lightbox_3xcb);
        $(this).dequeue();
    });
}

function close_lightbox_3xcb() {
    $('#popup_3xcb').hide();
    $('#shad').hide();
    $(this).unbind('click', close_lightbox_3xcb);
}

function in_array(needle, haystack, strict) {
    // http://kevin.vanzonneveld.net
    // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // *     example 1: in_array('van', ['Kevin', 'van', 'Zonneveld']);
    // *     returns 1: key
    var rKey = -1, strict = !!strict;
    if (haystack.length > 0) {
        for (key in haystack) {
            if ((strict && haystack[key] === needle) || (!strict && haystack[key] == needle)) {
                rKey = key;
                break;
            }
        }
    }
    return rKey;
}

function in_array_js(needle, haystack) {
    var rKey = -1;
    if (haystack.length > 0) {
        for (key in haystack) {
            if (haystack[key] == needle) {
                rKey = key;
                break;
            }
        }
    }
    return rKey > -1 ? true : false;
}

$.fn.recalImg = function (img) {
    var obj = $(this);
    var img;
    if (img == "" || img == null) {
        img = $("img", obj);
    } else {
        img = $(img, obj);
    }
    img.css({position: "", height: "", width: "", left: "", top: "", marginTop: "", marginLeft: ""});
    var widthObj = obj.outerWidth(true);
    var heightObj = obj.outerHeight(true);
    var widthImg = img.width();
    var heightImg = img.width();
    obj.css({position: "relative"});
    img.css({position: "absolute"});
    if ((heightImg > heightObj) || (widthImg > widthObj)) {
        if (widthImg > heightImg) {
            img.css({height: heightObj});
        } else if (widthImg <= heightImg) {
            img.css({width: widthObj});
        }
        var widthImgNow = img.outerWidth(true);
        var heightImgNow = img.outerHeight(true);
        var difHeight = (heightObj - heightImgNow) / 2;
        var difWidth = (widthObj - widthImgNow) / 2;
        if (widthImg > heightImg) {
            img.css({left: 0, top: 0});
        } else if (widthImg <= heightImg) {
            img.css({top: 0, left: 0});
        }
    } else {
        img.css({top: "0", left: "0"});
    }
    img.show();
};

function activeChange(p, c, s) {
    if ($(p).attr("value") == "") {
        $(c).css({color: "#aaa", fontStyle: "italic"});
    } else {
        $(c).css({color: "#000", fontStyle: "normal"});
    }
    c.text($('option:selected', s).text());
    if ($('option:selected', s).val() == "") {
        $(c).css({color: "#aaa", fontStyle: "italic"});
    }
}

$.fn.cbForm = function () {
    var t = $(this);
    var fs = $('.form_select', t);
    fs.css({opacity: "1"});
    fs.hover(function () {
        if (!$(this).hasClass("activ") && t.hasClass('active_bloc')) {
            $(this).css({opacity: "0.5"});
        }
    }, function () {
        if (!$(this).hasClass("activ") && t.hasClass('active_bloc')) {
            $(this).css({opacity: "1"});
        }
    });
    fs.click(function () {
        if (t.hasClass('active_bloc')) {
            fs.removeClass("activ");
            fs.css({opacity: "1"});
            $(this).addClass("activ");
            $(this).css({opacity: "0.5"});
            $(".type_carte option", t).removeAttr("selected");
            var typeCarte = $(this).attr("id");
            $("#type_carte_" + typeCarte, t).attr('selected', 'selected');
            p = $('.type_carte option:selected', t);
            c = $('.clone', $('.type_carte', t).parent('.selectStyled'));
            c.text(p.text());
            if ($(p).attr("value") == "") {
                $(c).css({color: "#aaa", fontStyle: "italic"});
            } else {
                $(c).css({color: "#000", fontStyle: "normal"});
            }
        }
    });
    $('.type_carte', t).change(function () {
        tcv = $('option:selected', this);
        var ctcv = tcv.val();
        fs.removeClass("activ");
        fs.css({opacity: "1"});
        if (ctcv != "") {
            $('.form_select#' + ctcv).addClass("activ");
            $('.form_select#' + ctcv).css({opacity: "1"});
        }
    });
}

function showrecuppanierlightbox() {
    var elname = 'recuppanier';
    var actualposition = document.documentElement.scrollTop;
    var modbox = document.getElementById(elname);
    var topconnexion = document.getElementById('topMenuConnexionButtonInside');
    var shad = document.getElementById('shad2');
    shad.style.display = "block";
    modbox.style.display = "block";
    if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('7.') != -1) {
        topconnexion.style.zIndex = -1;
    }
    addEventPromo(shad, 'click', closerecuppanier);
    window.scrollTo(0, actualposition);
}

function closerecuppanier() {
    var modbox = document.getElementById('recuppanier');
    var shad = document.getElementById('shad2');
    modbox.style.marginLeft = '';
    shad.style.display = "none";
    modbox.style.display = "none";
    document.body.style.overflow = '';
    document.getElementsByTagName("html")[0].style.overflow = "";
    delEventPromo(shad, 'click', closerecuppanier);
}

function addEventPromo(event, name_function) {
    if (document.all) document.getElementById('shad').attachEvent("on" + event, name_function);
    else document.getElementById('shad').addEventListener(event, name_function, false);
}

function delEventPromo(event, name_function) {
    if (document.all) document.getElementById('shad').detachEvent("on" + event, name_function);
    else document.getElementById('shad').removeEventListener(event, name_function, false);
}

function addFileInput(id_input, string) {

    var field = (typeof (string) != 'undefined') ? string : 'contactProduitPhoto';
    id = parseInt(id_input);
    var next_file = id + 1;
    var file = $('#' + field + id_input)[0].files[0];
    var error = false;
    var input = field == 'contactProduitPhoto' ? $('#photoProduit_display') : $('#photoProduitEndommage_display');
    var nb_input = field == 'contactProduitPhoto' ? $(input).val() : $(input).val();
    nb_input = parseInt(nb_input);
    size = file.size;
    type = file.type;
    var allowedType = new Array('image/jpeg', 'image/png', 'application/pdf'); // Type de fichiers autorisés
    if (size > 4000000) {
        alert('Poids Max : 4Mo');
        error = true;
    } else if (jQuery.inArray(type, allowedType) == -1) {
        alert('Format autoris&eacute; : jpg, png, pdf');
        error = true;
    }
    if (error) {
        if (id_input == 1) {
            $('#field_send').addClass('cache');
            $('#' + field + next_file).remove();
        }
        $('#' + field + id_input).val('');
    } else {
        if (id_input == 1) {
            $('#field_send').removeClass('cache');
        }
        if (field != 'filepj') {
            var params = (typeof (string) != 'undefined') ? ',"' + string + '"' : '';
            var style = (typeof (string) != 'undefined' && string != 'contactProduitPhotoEndommage') ? 'style="float: left; padding-left: 32px;padding-top:-2px;"' : '';
            $('#' + field + id_input).after("<input " + style + " class='product_file' id='" + field + next_file + "' value='' type='file' name='" + field + next_file + "' onchange='addFileInput(" + next_file + params + ")' /> ");
            $(input).attr('value', next_file);
        } else {
            $('#' + field + id_input).parent().find(".file_msg p").text(file.name);
        }
    }
}

function uploadfile() {//fonction pour valider le fichier en pj dans le formulaire message
    var error = false;
    var fname = $('#message_file').val();
    var fsize = $('#message_file')[0].files[0].size; //get file size
    var ftype = $('#message_file')[0].files[0].type; // get file type
    var allowedType = new Array('image/jpeg', 'image/png', 'application/pdf'); // Type de fichiers autorisés
    if (fsize > 4000000) {
        alert('Poids Max : 4Mo');
        error = true;
    } else if (jQuery.inArray(ftype, allowedType) == -1) {
        alert('Format autorise : jpg, png, pdf');
        error = true;
    }
    if (error) {
        $('#message_file').val('');
    } else {

    }
}

function openMultiShad(id) {

    // Show modbox and make sure closing routine won't fire more than once
    $("#" + id).fadeIn(200, function () {

        $("#" + id).slideDown(600);
    });

    if (id !== 'zoomBox') {
        $("#shad").css("display", "block").fadeTo("slow", 0.8).on("click", function () {
            closeMultiShad(id);
        });
    }

    if(id === "zoomBox") {

        $('#zoom_box_wrapper .swiper-slide').mousemove(function (event) {

            var slideOffset = $(this).offset();
            var relY = -1 * (event.pageY - slideOffset.top);

            $(this).find('img').css('top', relY + 100 + "px");
        });

        $('#zoom_box_wrapper .swiper-slide').on('click', function () {

            closeMultiShad('zoomBox');
        });
    }

    if ($("#buyNowSwiper").length > 0) {

        window.setTimeout(function () {

            buyNowSwiper = new Swiper("#buyNowSwiper", {

                width: 260,
                height: 390,
                sliderPerView: 1,
                navigation: {
                    nextEl: '.modboxNext',
                    prevEl: '.modboxPrev',
                },
            });
        }, 50);
    }

    bodyScrollLock();
}

function closeMultiShad(id) {

    $('#shad').off('click');

    // Hide modbox and shade and destroy shade's click event
    $('#' + id).slideUp("slow").queue(function () {

        if ($('#shad').is(":visible")) {
            $('#shad').fadeTo("slow", 0, function () {

                $('#shad').css('display', 'none');
            });
        }

        if ($('#shad_abox').is(":visible")) {
            $('#shad_abox').fadeTo("slow", 0, function () {

                $('#shad_abox').css('display', 'none');
            });
        }

        $(this).dequeue();
    });

    bodyScrollUnlock();
}

/* Close commande popup */
function close_popup_return(id) {

    $('#shad').off('click');
    $('#popup_numero_retour_' + id).slideUp("slow").queue(function () {
        if ($('#shad').is(":visible")) {
            $('#shad').fadeTo("slow", 0, function () {
                $('#shad').css('display', 'none');
            });
        }

        if ($('#shad_abox').is(":visible")) {
            $('#shad_abox').fadeTo("slow", 0, function () {
                $('#shad_abox').css('display', 'none');
            });
        }

        $(this).dequeue();

    });

    bodyScrollUnlock();
}

function deplieMsg(id) {
    $('.btn-deplier').removeClass('deplier-opened');
    $('.detail_message').slideUp();
    $('#conversation_msg_' + id).slideDown().parent().find('.btn-deplier').addClass('deplier-opened');
}

function showDetailOrder(order_id, order_type) {
    if (show_orders_by_type('orders_by_type', order_type, ROOT_PATH))
        $('#order_' + order_id).click();
    $('html, body').animate({
        scrollTop: ($('[data-btn-name="order_section"]').offset().top - 100)
    }, 500);
}

function changeLocale(locale, uri, site) {

    $.ajax({
        url: path_relative_root + 'ajax_get_uri_by_lang.php',
        type: 'post',
        data: {lang: locale, url: uri, site: site},
        success: function (data) {
            if (data != '') {

                var force = '';
                if (uri == '/sunco-paris.html') {
                    force = '?force';
                }

                location.href = data + force;

            }
        }
    });

}

function actionKeyCacheCache() {
    $('.key_cache_cache').bind('click', function () {
        var $objId = $(this).attr('id');
        openPopupCacheCache($objId);
    });
}

$.fn.new_styling_form = function (option) {
    var $obj = $(this);
    var param = $.extend({
        target_type: 'multi',
        type_class_sufix: 'styled',
        localName_new_elem: 'div',
        new_elem_id: true,
        height: 'auto',
        use_class: 'false',
        select_noValue_color: '#aaa'
    }, option);

    function animeElem(action, styled, height) {
        var useClass = param.use_class == true;
        styled.removeClass('unselected selected btndown');
        if (action == 'inActive') {
            styled.css({backgroundPosition: '0 0'}).addClass('unselected')
        }
        if (action == 'active') {
            styled.css({backgroundPosition: '0 -' + (height * 2) + 'px'}).addClass('selected');
        }
        if (action == 'btnDownToActive' || action == 'btnDownToInActive') {
            styled.addClass('btndown');
            if (action == 'btnDownToActive') {
                styled.css({backgroundPosition: '0 -' + (height * 1) + 'px'})
            }
            if (action == 'btnDownToInActive') {
                styled.css({backgroundPosition: '0 -' + (height * 3) + 'px'})
            }
        }
    }

    $obj.each(function () {
        var $objEach = $(this);
        var eachLocalName = $objEach[0].tagName.toLowerCase();
        var localNameValid = new Array('input', 'select');
        var flag = true;
        var eachType = $objEach[0].type;
        var isInputForm = $.inArray(eachLocalName, localNameValid) >= 0

        var acceptedType = new Array('radio', 'checkbox');
        var isAccepted = $.inArray(eachType, acceptedType) >= 0;
        var idElem = $objEach.prop('id');


        if (!isInputForm || (isInputForm == 'input' && !isAccepted)) {
            flag = false;
        }

        var elemType = param.target_type;
        if (elemType = 'multi') {
            elemType = eachType;
        } else if (elemType != eachType) {
            flag = false;
        }
        if ($objEach.data('isStyled')) {
            flag = false;
        }

        if (flag) {
            var prefix = (eachLocalName == 'input') ? eachType : eachLocalName;
            var styledClass = prefix + '_' + param.type_class_sufix
            if (param.type_class_sufix == '') {
                styledClass = prefix;
            }

            if (!$objEach.prev().is(param.localName_new_elem + '.' + styledClass)) {
                var $styledElem = $(document.createElement(param.localName_new_elem));
                $objEach.before($styledElem);
                $styledElem.addClass(styledClass).css({cursor: 'pointer'});
                if (eachLocalName == 'input') {
                    $objEach.css({display: 'none'});
                }
                if (eachLocalName == 'select') {
                    $objEach.css({position: 'relative', opacity: 0, zIndex: 5});
                }
            } else {
                var $styledElem = $(param.localName_new_elem + '.' + styledClass);
            }

            if (param.new_elem_id) {
                $styledElem.prop('id', eachLocalName + $objEach.prop('id'))
            }

            var objHeight = param.height;
            if (objHeight == 'auto') {
                objHeight = $styledElem.height();
            }
            $objEach.data('isStyled', true);
            var objChecked = $objEach.is(':checked');

            if (eachLocalName == 'input') {
                if (objChecked) {
                    animeElem('active', $styledElem, objHeight);
                } else {
                    animeElem('inActive', $styledElem, objHeight);
                }
            }

            if (eachLocalName == 'input') {
                if (eachType == 'checkbox') {
                    $styledElem.on({
                        'mousedown': function (e) {
                            var objChecked = $objEach.is(':checked');
                            if (e.which == 1) {
                                if (objChecked) {
                                    animeElem('btnDownToInActive', $styledElem, objHeight);
                                } else {
                                    animeElem('btnDownToActive', $styledElem, objHeight);
                                }
                            }
                        }, 'mouseup': function (e) {
                            var objChecked = $objEach.is(':checked');
                            if (e.which == 1) {
                                if (objChecked) {
                                    $objEach.prop('checked', null);
                                } else {
                                    $objEach.prop('checked', 'checked');
                                }
                                $objEach.trigger('change');
                            }
                        }
                    });
                    $objEach.on({
                        'change': function () {
                            var objChecked = $objEach.is(':checked');

                            if (objChecked) {
                                animeElem('active', $styledElem, objHeight);
                            } else {
                                animeElem('inActive', $styledElem, objHeight);
                            }
                        }
                    });

                }
                if (eachType == 'radio') {
                    var eachName = $objEach[0].name;
                    var $otherRadio = $obj.not($objEach).filter('[type=radio][name=' + eachName + ']');

                    $styledElem.on({
                        'mousedown': function (e) {
                            var objChecked = $objEach.is(':checked');
                            if (e.which == 1) {
                                if (!objChecked) {
                                    animeElem('btnDownToActive', $styledElem, objHeight);
                                }
                            }
                        }, 'mouseup': function (e) {
                            var objChecked = $objEach.is(':checked');
                            if (e.which == 1) {
                                if (!objChecked) {
                                    $otherRadio.prop('checked', null);
                                    $objEach.prop('checked', 'checked');
                                    animeElem('active', $styledElem, objHeight);
                                    $otherRadio.each(function () {
                                        var otherEach = $(this);
                                        animeElem('inActive', otherEach.prev('.' + styledClass), objHeight);
                                    })
                                }
                                $objEach.trigger('change');
                            }
                        }
                    });
                    $objEach.on({
                        'change': function () {
                            var objChecked = $objEach.is(':checked');
                            if (objChecked) {
                                animeElem('active', $styledElem, objHeight);
                                $otherRadio.each(function () {
                                    var otherEach = $(this);
                                    animeElem('inActive', otherEach.prev('.' + styledClass), objHeight);
                                });
                            }
                        }
                    });
                }
            }
            if (eachLocalName == 'select') {
                if ($styledElem.text() == '') {
                    $styledElem.text($('option:selected', $objEach).text());
                }
                if ($('option:selected', $objEach).val() == '' || $('option:selected', $objEach).val() == undefined) {
                    $styledElem.css({color: param.select_noValue_color, fontStyle: 'italic'});
                } else {
                    $styledElem.css({color: '', fontStyle: ''});
                }
                $objEach.on({
                    'change': function () {
                        if ($('option:selected', $objEach).val() == '' || $('option:selected', $objEach).val() == undefined) {
                            $styledElem.css({color: param.select_noValue_color, fontStyle: 'italic'});
                        } else {
                            $styledElem.css({color: '', fontStyle: ''});
                        }
                        $styledElem.text($('option:selected', $objEach).text());
                    }
                })
            }
        }

    });
};

$.fn.selectChangeAdr = function () {
    var $obj = $(this);
    var obj_val = $obj.val();
    var id_transp = $obj.attr('id');
    var data = {};
    var $changeContainer = $('.wrapper_form_adresse', $obj.closest('.adresse_change'));
    data.act = 'ajout';
    data.id_transp = id_transp;
    data.no_encode_ajax = true;
    data.modif_type = 'ajax';

    if (obj_val != '') {

        if (obj_val != 0) {
            data.idAdresse = obj_val;
            data.act = 'modif';
        }
        if ($changeContainer.is('.show')) {
            $changeContainer.removeClass('show').slideUp().html('');
        }
        $.ajax({
            type: 'get',
            data: data,
            url: path_relative_root + 'livraison-ajax.php',
            success: function (response) {
                var $responce = $(response);
                var min_form = $('.min_form', $responce).html();
                var $form = $('#adresseForm', $responce);
                var all_form;

                $changeContainer.html($form.html(min_form)).css({display: 'none'}).slideDown();
                $('.styled').new_styling_form({
                    type_class_sufix: '',
                    localName_new_elem: 'span'
                });
                if (!$changeContainer.is('.show')) {
                    $changeContainer.addClass('show').slideDown()
                }
                try {
                    new DeliveryPage().init();
                } catch (e) {
                    console.log(e);
                }

                if ($('#autocomplete_addr').length > 0) {
                    initialize_google_place('delivery');
                }
            
                $('.type_adresse', '.choix_type_adresse.delivery').on('click', function () {
                    $(this).show_adresse_title($('.type_adresse', '.choix_type_adresse.delivery'), '#adresseTitre');
                });

                var country_select = '';

                if (typeof $("#pays").val() != 'undefined') {

                    country_select = $("#pays").find(":selected").data('role').toLowerCase();

                    $("#pays").change(function () {

                        if (typeof $(this).find(":selected").data('role') != 'undefined') {

                            country_select = $(this).find(":selected").data('role').toLowerCase();

                        }

                        if (typeof $("#telephone").val() != 'undefined') {

                            $("#telephone").intlTelInput("setCountry", country_select);

                        }

                        if (typeof $("#mobile").val() != 'undefined') {

                            $("#mobile").intlTelInput("setCountry", country_select);

                        }

                    });

                } else {

                    country_select = "fr";

                }
            }
        });

    }
};

var inAnime = false;
$.fn.advanceSlide = function (to, callback) {

    var $obj = $(this);
    var isVisible = $obj.is(':visible');
    var callbackfn = function () {
        if (callback && typeof callback === 'function') {
            return callback();
        }
        return false;
    }
    if ($obj.data('height') == undefined || $obj.data('height') == 0) {
        if (!isVisible) {
            $obj.css({display: 'block', opacity: 0, position: 'absolute'});
        }
        $obj.data('height', $obj.height());
        if (!isVisible) {
            $obj.css({display: 'none', opacity: '', position: ''});
        }
    }

    var objH = $obj.data('height');
    if (inAnime == false) {
        inAnime = true;
        if (to == 'show' && !isVisible) {
            $obj.stop().css({display: 'block', overflow: 'hidden', height: 0}).animate({height: objH}, function () {
                $obj.css({overflow: '', height: ''});
                inAnime = false;
                callbackfn();
            });
        } else if (to == 'hide' && isVisible) {
            $obj.stop().css({display: 'block', overflow: 'hidden', height: objH}).animate({height: 0}, function () {
                $obj.css({display: 'none', overflow: '', height: ''});
                inAnime = false;
                callbackfn();
            });
        } else {
            inAnime = false;
        }
    }
};

function billDisplay(that) {

    var $obj = $('#wrapper_bill');
    var $this = $(that);

    if (!$this.is(':checked')) {

        $obj.stop().slideDown();
    } else {

        $obj.stop().slideUp();
    }
}

(function () {

    "use strict";

    var stickyCnt = 0;

    function initStickies() {

        var stickies = document.getElementsByClassName("sticky");

        Array.prototype.slice.call(stickies).forEach(function (sticky) {

            if (sticky.id !== "site_head_wrap") {

                if (sticky.id === "") {

                    stickyCnt += 1;
                    sticky.id = "sticky-" + stickyCnt;
                }

                sticky = new ScrollLock(sticky.id);
            }
        });
    }

    $(initStickies);
}());

$(function () {
    $(document).on("click", ".button", btnLoaderTrigger);
});

$(function () {

    'use strict';
    $("#cart_list").lionbars();
    $("#cart_top").on('mouseenter touchstart', function (evt) {
        $("#cart_list").lionbars();
    });
});

/**
 * Enables hover state on any element when using a touch based device
 */

(function () {

    function nevermind() {

        return;
    }

    document.body.addEventListener("click", nevermind);
}());

$(function () {

    $("#com_onglet").on("click", ".onglet", function (evt) {

        if ($("a", this).length && !$(this).hasClass("actif")) {

            $(this).addClass("actif").siblings().removeClass("actif");
        }
    });

    var cgvTableInfos = $(".cgvTableInfos");
    $(".cgvTableCountry").on("click", function () {

        $(this).toggleClass("opened");
        $(this).next(cgvTableInfos).toggleClass("opened");
        $(this).next(cgvTableInfos).css("width", "100%");
    });

    window.addEventListener("scroll", fix_elements);
    fix_elements();
});

function fix_elements() {
    fix_filter("#site_head_wrap", true, 71);

    if ($('#site_head_wrap').hasClass('promo_on')) {
        fix_filter('#fix_menu_compte', '#site_head_wrap', true, 159);
    } else {
        fix_filter('#fix_menu_compte', '#site_head_wrap', true, 108);
    }
}

function fix_filter(s_element, s_offset, recalc, h) {
    if ($(s_element).length) {
        if (recalc) {
            $(s_element).stick_in_parent({inner_scrolling: false, offset_top: h, recalc_every: 1});
        } else {
            $(s_element).stick_in_parent({inner_scrolling: false, offset_top: h});
        }
    }
}

/**
 * Peremt de récupérer les stocks des tailles par couleur ainsi que leur stock
 * @author Timothy Beard / Aurélien Renault
 * @since  2017-09-27
 */
function setAvailableSizes() {

    if (window.json_colisages !== undefined) {

        var sizes_tab = window.json_colisages[$(this).val()];
        var size_box, is_disabled;

        for (size_id in sizes_tab) {

            if (sizes_tab.hasOwnProperty(size_id)) {

                size_box = $('[name="itm_size"]').filter('[value="' + size_id + '"]');
                is_disabled = !sizes_tab[size_id];

                if( !is_disabled ) {
                    size_box.removeClass('disabled');
                }
                else {
                    size_box.addClass('disabled');
                }

            }
        }
    }
}


function createCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, "", -1);
}

/* AUTO-TOGGLE ON CONTACT PAGE START */
$(function () {
    var get_param = getUrlParams('sq');

    if (get_param != '' && Object.prototype.toString.call(get_param) == '[object String]') {
        get_param = get_param.split(',');
        $('#service_' + get_param[0] + ' span.item_title').trigger("click");
        $('#question_' + get_param[0] + '_' + get_param[1] + ' span.question_title').trigger("click");
        setTimeout(function () {
            $('html, body').animate({
                scrollTop: ($('#question_' + get_param[0] + '_' + get_param[1]).offset().top - $('nav.mainMenu').height() - 30)
            }, 300);
        }, 300);
    }
});

function getUrlParams(prop) {
    var params = {};
    var search = decodeURIComponent(window.location.href.slice(window.location.href.indexOf('?') + 1));
    var definitions = search.split('&');

    definitions.forEach(function (val, key) {
        var parts = val.split('=', 2);
        params[parts[0]] = parts[1];
    });

    return (prop && prop in params) ? params[prop] : params;
}

/* AUTO-TOGGLE SCROLL ON CONTACT PAGE END */

/** Connexion formulaire start **/

$(function (){
    if($('body').hasClass('in_tunnel') != true){
        $(".wrapper_login.left .ancientCustomerLogin p").click(function(){
            showBlocForm('is_client', null);
        });

        $(".wrapper_login.right .ancientCustomerLogin p").click(function(){
            showBlocForm('is_not_client', null);
        });
    }
    if ($("body.nos_engagements").length == 0 ) {
        // Select all links with hashes
        $('a[href*="#"]')
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function(event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                &&
                location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top - 113
                    }, 1000, function() {
                        // Callback after animation
                        // Must change focus!
                        var $target = $(target);
                        $target.focus();
                        if ($target.is(":focus")) { // Checking if the target was focused
                            return false;
                        } else {
                            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                            $target.focus(); // Set focus again
                        };
                    });
                }
            }
        });
    }
});

/** Connexion formulaire end **/

if($('body').hasClass('footer_company')){
    $( document ).ready(function() {

        $(window).scroll(function (event) {
            var scroll = $(window).scrollTop();
            $('body.footer_company #site_head_wrap').toggleClass('changeColor',
             //add 'ok' class when div position match or exceeds else remove the 'ok' class.
                scroll > $('#changeMenuColor').offset().top + 125
            );
        });
        //trigger the scroll
        $(window).scroll();//ensure if you're in current position when page is refreshed
    });
}

$.fn.initAssocBloc = function() {

    var element = $(this);

    function displayItem(element) {
        var list = [];

        $("a", element).each(function(index) {
            list.push($(this));
        });

        list = shuffle(list);

        var length = list.length;
        if (length > 4) {
            length = 4;
        }
        element.html('');
        for (var i = 0 ; i < length ; i++) {
            element.append(list[i]);
        }
    }

    /**
     * Shuffles array in place.
     * @param {Array} a items An array containing the items.
     */
    function shuffle(a) {
        var j, x, i;
        for (i = a.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = a[i];
            a[i] = a[j];
            a[j] = x;
        }
        return a;
    }

    displayItem(element);

};

/*********************************
Custom Select Menu
 *********************************/

function initCustomSelect(select) {
    var x, i, j, selElmnt, a, b, c;
    if (select === undefined) {
        select = 'custom-select';
    }
    x = document.getElementsByClassName(select);
    // Si jamais l'element est déja customisé alors on n'y touche pas
    if ($('.' + select + ' .select-items').length > 0) {
        return;
    }
    for (i = 0; i < x.length; i++) {
        selElmnt = x[i].getElementsByTagName("select")[0];
        a = document.createElement("DIV");
        a.setAttribute("class", "select-selected");
        a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
        x[i].appendChild(a);
        b = document.createElement("DIV");
        b.setAttribute("class", "select-items select-hide");
        for (j = 0; j < selElmnt.length; j++) {
            // Si la valur est vide alors on ne la prend pas en compte
            if (selElmnt.options[j].value === '') {
                continue;
            }
            c = document.createElement("DIV");
            c.innerHTML = selElmnt.options[j].innerHTML;
            if (selElmnt.options[j].selected) {
                c.setAttribute("class", "same-as-selected");
            }
            c.addEventListener("click", function (e) {
                var y, i, k, s, h, l;
                s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                h = this.parentNode.previousSibling;
                l = s.length;
                for (i = 0; i < l; i++) {
                    if (s.options[i].innerHTML == this.innerHTML) {
                        s.selectedIndex = i;
                        // Trigger pour lancer l'evènement
                        $(s).trigger('change');
                        h.innerHTML = this.innerHTML;
                        y = this.parentNode.getElementsByClassName("same-as-selected");
                        for (k = 0; k < y.length; k++) {
                            y[k].removeAttribute("class");
                        }
                        // Pour signaler que c'est cet élément qui doit être sélectionné
                        this.setAttribute("class", "same-as-selected");
                        break;
                    }
                }
            });
            b.appendChild(c);
        }
        x[i].appendChild(b);
        a.addEventListener("click", function (e) {
            e.stopPropagation();
            // Raz de tous les autres select
            closeAllSelect(this);
            // On cache/affiche la liste
            this.nextSibling.classList.toggle("select-hide");
            // On change la direction de la fleche
            this.classList.toggle("select-arrow-active");
        });
    }
}

function updateCustomSelect(select_str) {

    if (select_str === undefined) {
        select_str = '.custom-select';
    }
    var select = $(select_str);

    // Evenement sur le select pour bien le gérer
    $('.select-selected', select).on("click", function (e) {
        e.stopPropagation();
        // Raz de tous les autres select
        closeAllSelect(this);
        // On cache/affiche la liste
        this.nextSibling.classList.toggle("select-hide");
        // On change la direction de la fleche
        this.classList.toggle("select-arrow-active");
    });
    // Maintenant pour chacun de ses fils on va mettre un evenement
    $('.select-items div', select).on('click', function () {
        var y, i, k, s, h, l;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        h = this.parentNode.previousSibling;
        l = s.length;
        for (i = 0; i < l; i++) {
            // En parcourant le select on va conparer le innerhtml du select avec le personalisé pour selectionner le bon element
            if (s.options[i].innerHTML == this.innerHTML) {
                s.selectedIndex = i;
                // Trigger pour lancer l'evènement
                $(s).trigger('change');
                h.innerHTML = this.innerHTML;
                y = this.parentNode.getElementsByClassName("same-as-selected");
                for (k = 0; k < y.length; k++) {
                    y[k].removeAttribute("class");
                }
                // Pour signaler que c'est cet élément qui doit être sélectionné
                this.setAttribute("class", "same-as-selected");
                break;
            }
        }
    });
}

function closeAllSelect(elmnt) {
    var x, y, i, arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    for (i = 0; i < y.length; i++) {
        if (elmnt == y[i]) {
            arrNo.push(i)
        } else {
            y[i].classList.remove("select-arrow-active");
        }
    }
    for (i = 0; i < x.length; i++) {
        if (arrNo.indexOf(i)) {
            x[i].classList.add("select-hide");
        }
    }
}

document.addEventListener("click", closeAllSelect);
$(document).ready(function () {
    initCustomSelect();
    $(".custom-select select").each(function () {
        var count = $(this).find("option").not(':empty').length;
        if (count == 1) {
            $('.custom-select .select-selected').addClass("removeAfter");
        }
    });
});


$("#search_home").on('input', function() {
    var value = $("#search_home").val();

    if(value.length >= 3){
        $('body').addClass('search_in');
    } else {
        $('body').removeClass('search_in');
    }
});

$(function(){
    /**
     * Change table tarif transport position under
     * Article IV - livraison
     * Page CGV
     *
     */
    moveTablePos();

    /**
     * Page CGV
     */
    addSpaceBetweenLogo();
});


/***** PROD ASSOCS DATA LAYERS *****/

function dataLayer_associated_product () {

    if (window.$('#gondole_portez')) {
        var product_id = this.getAttribute('data-prod') ? event.currentTarget.getAttribute('data-prod') : '';

        if (product_id !== '') {
            var productObj = data_layer_products[product_id];

            var prod = productObj;
            dataLayer.push({
                'event': 'productClick',
                'ecommerce': {
                    'click': {
                        'actionField': {'list': productObj['category']},      // Optional list property.
                        'products': [prod]
                    }
                },
                'eventCallback': "function () {document.location = productObj.url}"
            });
        }
    }
}

function moveTablePos() {
    var $contentWrapper = $('.order_cgv .wrapper_content_sat.page_text .content_sat'),
        $table = $contentWrapper.find('.wrapperCgvTable'),
        $lastEl = $contentWrapper.find('#row_bloc_4 #bloc_4 strong').last();

    if($table.length && $lastEl.length) {
        $table.insertBefore($lastEl);
    }

}


/**
 * Page CGV
 * add space to logo image
 */
function addSpaceBetweenLogo() {
    var bloc3 = $('.order_cgv .content_sat #row_bloc_3 #bloc_3');
    var img = bloc3.children('img[src$="systempay.jpg"]');

    if(img.length) {
        img.before('<br>');
    }

}

/* Inverser placement des textes sur la page connexion */
$("#js-signUpForm .intro").insertBefore("#js-signUpForm fieldset.w-lines-group");


/* Replacer les elements du pop up inscription à la newsletter */
$("#formu_inscription_news .content_newspopup .pop_txt_listing").insertAfter("#formu_inscription_news .content_newspopup form.w-form .w-form-line-container .w-form-line label.w-email-input");

setTimeout(function () {

    if ($("body.nos_engagements").length ) {
        $('a[href*="#"]').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html,body').animate({
                      scrollTop: target.offset().top-120 // valeur équivalent à la hauteur à dépasser
                    }, 800);
                    return false;
                }
            }

        });
    }

}, 1500);

/* CDN */

setTimeout(function () {
    $("#copyright #container_footer .wrap_links .wrap_seos .footerLinks #listes tr td h2").each(function(){
        var footerh2 = $(this);
        $(this).replaceWith('<p class="footerh2">' + footerh2.html() + '</p>');
    });

    $("#copyright #container_footer .wrap_links .wrap_seos .footerLinks #listes tr td h3").each(function(){
        var footerh3 = $(this);
        $(this).replaceWith('<p class="footerh3">' + footerh3.html() + '</p>');
    });

    $('#cookiesLightbox section h4 .title').each(function() {
        var cookiesh4 = $(this);
        $(this).replaceWith('<p class="cookiesh4">' + cookiesh4.text() + '</p>');
    });

    $('body.footer_contact .hashtag_suncoo .social_network .aside_title .hast_title').each(function() {
        var hashsuncoo = $(this);
        $(this).replaceWith('<h2 class="hashsuncoo">' + hashsuncoo.html() + '</h2>');
    });

    $('.homepage .main_wrapper div > .home-module.cover-module:nth-of-type(1) .home-module-contents .home-module-pretitle , .homepage .main_wrapper div > .home-module.cover-module:nth-of-type(1) .home-module-contents .home-module-title').wrapAll('<h2 class="cover_module_h2"></h2>');

}, 1000 );


$( function() {
    setTimeout(function () {
        $('body.store_locator #store_international .store_international[data-id="30"]').prependTo('body.store_locator #store_international .store_list');
    }, 2000 );
});